import React from 'react';
import { graphql } from 'gatsby';
import { PageHero, PageWithSubNav, PlatformTabs } from 'docComponents';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from './platforms/_index.js';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Elevation"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Layering"
        tierThree="Elevation"
      />

      <PlatformTabs>
        <WebTab data={data} type="web" />
        <ReactNativeTab type="reactnative" />
        <AndroidTab type="android" />
        <AppleTab type="apple" />
        <OtherTab type="other" />
      </PlatformTabs>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query elevationsGuidelinesCode {
    allElevationsYaml {
      edges {
        node {
          elevations {
            name
            value
            sass_var
          }
        }
      }
    }
  }
`;
